<template>
  <v-card
      elevation="5"
      outlined
      rounded
      width="380"
      height="500"
  >
    <v-card-title class="d-flex align-center justify-center">
      <v-card
          elevation="5"
          class="w-100 login-form-title-card"
          height="120"
          color="primary">
        <v-card-title class="w-100 text-h6 text-center d-flex align-center justify-center white--text">
          ورود به پنل مدیریت
        </v-card-title>
      </v-card>
    </v-card-title>

    <v-card-subtitle>
      <div class="mt-6">
        <v-btn
            @click="$emit('onBack')"
            text
            color="primary"
            large
        >

          <v-icon class="ml-2">
              mdi-arrow-right
          </v-icon>
          ویرایش شماره
          {{ phoneNumber }}


        </v-btn>
      </div>
    </v-card-subtitle>

    <v-card-text class="pa-8">

      <v-otp-input
          @finish="submit"
          @keyup.enter="submit"
          type="number"
          hide-spin-buttons
          outlined
          v-model="code"
          length="5"
          class="mb-8"
      ></v-otp-input>
      <v-btn
          :disabled="Boolean(time.count)"
          color="main"
          large
          text
          elevation="1"
          @click="reSubmitVerifyCode"
      >
        <div :class="[time.count ? 'black--text':'white--text']">
          <span class="black--text" v-if="time.count">
            00:{{ getTimeCounter }}
            <span class="black--text">
              تا
            </span>
          </span>
          ارسال مجدد کد تایید
        </div>
      </v-btn>
    </v-card-text>

  </v-card>
</template>

<script>

export default {
  name: "VerifyForm",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: String,
      default: "30"
    },
    phoneNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      code: '',
      time: {
        interval: null,
        count: 0,
      }
    }
  },

  methods: {
    submit() {
      this.$emit('onSubmit', this.code);
    },
    async reSubmitVerifyCode() {
      await this.$emit('onReSubmit', this.phoneNumber);
      this.initTimer();
    },

    initTimer() {
      this.time.count = this.timeout;
      this.time.interval && clearInterval(this.time.interval);
      this.time.interval = setInterval(() => {
        if (this.time.count > 0) {
          this.time.count--
        } else {
          clearInterval(this.time.interval);
        }
      }, 1000);
    },
  },

  computed: {
    getTimeCounter() {
      return String(this.time.count).padStart(2, '0')
    },
  },

  mounted() {
    this.initTimer();
  },
}
</script>

<style scoped>
.login-form-title-card {
  position: relative;
  top: -50px;
}
</style>